<template>
  <b-modal
    body-class="px-4 pb-4 pt-2"
    centered
    v-model="show"
    header-class="header-class-modal-withdraw-request"
    hide-footer
    size="lg"
  >

    <div v-if="is_mexican === null">
      <h2 class="text-center">{{ $t('dashboard.uploadReceive') }}</h2>
      <p class="avenir-medium text-center mb-2">{{ $t('dashboard.ifYouAreCreator') }}</p>

      <b-button class="button-upload-invoice mb-2" @click="is_mexican = true;" id="2">
        <strong class=""><b-icon icon="file-earmark-text"></b-icon> {{ $t('dashboard.receiveMexico') }}</strong>
        <p class="avenir-medium mb-0">{{ $t('dashboard.receiveMexicoInfo') }}</p>
      </b-button>

      <b-button class="button-upload-invoice" @click="is_mexican = false">
        <strong class=""><b-icon icon="file-earmark-text"></b-icon> {{ $t('dashboard.receiveInternational') }}</strong>
        <p class="avenir-medium mb-0">{{ $t('dashboard.receiveInternationalInfo') }}</p>
      </b-button>
    </div>
    <feather-icon icon="ArrowLeftIcon" size="20" class="cursor-pointer" @click="is_mexican = file_pdf = file_xml = null; " v-if="is_mexican !== null"></feather-icon>

    <div v-if="is_mexican">
      <h2 class="text-center">{{ $t('dashboard.incoiceMexican') }}</h2>
      <p class="avenir-medium text-center mb-2 white-space-pre-line">{{ $t('dashboard.pleaseInclude') }}</p>

      <div class="container-file w-100 mb-2" :class="{'custom-container-file': file_pdf}">
        <div class="container-text" v-if="!file_pdf">
          <strong class=""><b-icon icon="file-earmark-text"></b-icon> {{ $t('dashboard.invoicePdf') }}</strong>
          <p class="avenir-medium mb-0">{{ $t('dashboard.invoicePdfUpload') }}</p>
        </div>
        <div v-else class="d-flex justify-content-between position-relative w-100">
          <div class="d-flex align-items-center w-80 position-relative">
            <b-avatar :text="'.pdf'" square class="avatar-preview-sample"></b-avatar>
            <span class="text-overlfow">{{ file_pdf.name }}</span>
          </div>
          <b-button variant="flat-secondary" @click="file_pdf = null"><feather-icon icon="TrashIcon" size="20"></feather-icon> </b-button>
        </div>
        <b-form-file accept=".pdf" ref="input-file-invoice" class="custom-input" :class="{'d-none': file_pdf}" v-model="file_pdf"></b-form-file>
      </div>

      <div class="container-file w-100 mb-2" :class="{'custom-container-file': file_xml}">
        <div class="container-text" v-if="!file_xml">
          <strong class=""><b-icon icon="file-earmark-text"></b-icon> XML</strong>
          <p class="avenir-medium mb-0">{{ $t('dashboard.invoiceCSV') }}</p>
        </div>
        <div v-else class="d-flex justify-content-between position-relative w-100">
          <div class="d-flex align-items-center w-80 position-relative">
            <b-avatar :text="'.xml'" square class="avatar-preview-sample"></b-avatar>
            <span class="text-overlfow">{{ file_xml.name }}</span>
          </div>
          <b-button variant="flat-secondary" @click="file_xml = null"><feather-icon icon="TrashIcon" size="20"></feather-icon> </b-button>
        </div>
        <b-form-file accept=".xml" ref="input-file-invoice" class="custom-input" :class="{'d-none': file_xml}" v-model="file_xml"></b-form-file>
      </div>
    </div>

    <div v-else-if="is_mexican === false" class="mb-">
      <h2 class="text-center">{{ $t('dashboard.receiveInternational') }}</h2>
      <p class="avenir-medium text-center mb-2 white-space-pre-line">{{ $t('dashboard.loadInvoiceInternational0') }} <a :href="`${host}/assets/brandme_invoice_international.pdf`" target="_blank">{{ $t('creator.here') }}</a> {{ $t('dashboard.loadInvoiceInternational1') }} <a @click="downloadFile(`${host}/assets/brandme_template_invoice.xlsx`)">{{ $t('creator.here') }}</a> {{ $t('dashboard.loadInvoiceInternational2') }}</p>

      <div class="container-file w-100 mb-2" :class="{'custom-container-file': file_pdf}">
        <div class="container-text" v-if="!file_pdf">
          <strong class=""><b-icon icon="file-earmark-text"></b-icon> {{ $t('dashboard.uploadInvoiceInternational') }}</strong>
          <p class="avenir-medium mb-0">{{ $t('dashboard.uploadInvoiceInternationalInfo') }}</p>
        </div>
        <div v-else class="d-flex justify-content-between position-relative w-100">
          <div class="d-flex align-items-center w-80 position-relative">
            <b-avatar :text="'.pdf'" square class="avatar-preview-sample"></b-avatar>
            <span class="text-overlfow">{{ file_pdf.name }}</span>
          </div>
          <b-button variant="flat-secondary" @click="file_pdf = null"><feather-icon icon="TrashIcon" size="20"></feather-icon> </b-button>
        </div>
        <b-form-file accept=".pdf" ref="input-file-invoice" class="custom-input" :class="{'d-none': file_pdf}" v-model="file_pdf"></b-form-file>
      </div>
    </div>
    <b-button variant="blue-button" class="blue-button w-100" :disabled="handle_disabled" v-if="is_mexican !== null" @click="requestMoney">{{ $t('dashboard.loadInvoice') }}</b-button>
  </b-modal>
</template>
    
<script>
import { 
  BModal,
  BButton,
  BFormFile,
  BAvatar,
} from "bootstrap-vue";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { requestBalance } from '@/services/finance';
import { downloadFile } from "@/libs/utils/others";

export default {
  name: "ModalWithdrawMoney",
  components: {
    BModal,
    BButton,
    BFormFile,
    BAvatar,
  },
  data() {
    return {
      show: this.show_modal,
      is_mexican: null,
      file_pdf: null,
      file_xml: null,
      host: window.location.origin,
      downloadFile
    };
  },
  props: {
    show_modal: {
      type: Boolean,
      required: true,
    },
    transactions_ready_withdraw: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    handle_disabled() {
      if (this.is_mexican) {
        return this.file_pdf === null || this.file_xml === null;
      } else {
        return this.file_pdf === null;
      }
    }
  },
  methods: {
    handleValidateTypes() {
      const accepted_types = ['pdf', 'xml'];
      const files = [this.file_pdf.type.split('/')[1]];      
      if (this.is_mexican) {
        files.push(this.file_xml.type.split('/')[1]);
      }
   
      return files.every(type => accepted_types.includes(type));
    },
    openFileInput() {
      setTimeout(() => {
        this.$refs['input-file-invoice'].$refs['input'].click()
      }, 20);
    },
    requestMoney() {
      if (this.handleValidateTypes()) {
        const form_data = new FormData();
        this.transactions_ready_withdraw.forEach(i => {
          form_data.append('transactions_uuid', i)
        });
        if (this.is_mexican) form_data.append('xml_file', this.file_xml)
        form_data.append('tax_file', this.file_pdf)

        requestBalance(form_data).then((response) => {
          console.log(response)
          if (response.status === 'OK') {
            this.$emit('update_transaction')

            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('creator.requestCollect'),
                  text: this.$t('creator.yourRequest'),
                  icon: 'DollarSignIcon',
                  variant: 'success',
                }
              })
            })
          } else {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: 'Error',
                  text: response.data.response.message,
                  icon: 'XIcon',
                  variant: 'danger',
                }
              })
            })
          }
        }).catch(() => {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('search.check_influencer_title'),
                text: this.$t('creator.noTransactionSucccess'),
                icon: 'XIcon',
                variant: 'danger',
              }
            })
          })
        })
      } else {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: 'Error',
              text: this.$t('dashboard.invalidType'),
              icon: 'XIcon',
              variant: 'danger',
            }
          })
        })
      }
    },
  },
};
</script>
<style lang="scss">
.header-class-modal-withdraw-request {
  background-color: white !important;
}
.button-upload-invoice {
  background-color: white !important;
  padding: 1rem !important;
  color: #444050 !important;
  box-shadow: none !important;
  border-color: #d3d3d4 !important;
  text-align: left !important;
  width: 100%;
  &:focus {
    background-color: white !important;
    outline: 2px solid #0096fe;
  }
  strong {
    display: block;
    margin-bottom: 0.7em;
  }
  p {
    font-size: 13px;
    margin-left: 1.3rem;
  }
}
.custom-input .custom-file-label {
  border: 1px dashed #3483fa !important;
  cursor: pointer;
  color: transparent;
  height: 80px !important;
  background-color: rgba(220, 209, 209, 0.388);
  background: transparent !important;
  &::after {
    display: none;
  }
}
.custom-input .custom-file-label:hover {
    border: 2px solid #3483fa !important;
    cursor: pointer;
}
.avatar-preview-sample {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 1em !important;
  margin: 0 1em 0 0;
  background-color: #e5e5e6;
  color: #444;
}
</style>
<style lang="scss" scoped>
.custom-container-file {
  border: 1px solid #d3d3d4;
  border-radius: .4rem;
  padding: 0.6em;
}
.text-overlfow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.container-file {
  position: relative;
  height: 80px;
  background-color: none;
  cursor: pointer;
  .w-80 {
    width: 80%;
    @media(max-width: 400px) {
      width: 60%;
    }
  }

  .container-text {
    position: absolute;
    top: 1.2em;
    left: 1.2em;
    
  }
}
.white-space-pre-line {
  white-space: pre-line;
}
p {
  color: #6d6b77;
}
h2 {
  color: #444050 !important;
}

a {
  color: #0096fe !important;
}
</style>